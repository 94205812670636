import * as React from "react";
import { Parallax } from "react-scroll-parallax";
import { useWindowDimensions } from "@gravitywelluk/react-hooks";

import styled, { css } from "src/theme/styled";
import FullWidthColumnWrapper from "src/components/confines-wrappers/full-width-column-wrapper";
import Panel from "src/components/panel-wrapper";
import Link from "src/components/link";
import IntervalFilmsAppImg from "src/assets/images/mvp/interval-films-app.png";

/**
 * "Interval films" section
 */
export const IntervalFilmsSection: React.FC = () => {
  const { width } = useWindowDimensions();

  /**
   * Sets the x, y parallax start and end percentages according to the devices
   * dimensions.
   */
  const parallaxXY = React.useMemo(() => {
    if (width) {
      // Desktop and upwards
      if (width >= 1280) {
        return {
          copy: { y: [ -25, 25 ] },
          image: { y: undefined }
        };
      }

      // Tablet (large) and upwards
      if (width >= 768) {
        return {
          copy: { y: [ -15, 15 ] },
          image: { y: undefined }
        };
      }

      // Tablet (small) and upwards
      if (width >= 600) {
        return {
          copy: { y: undefined },
          image: { y: [ 14, -4 ] }
        };
      }

      // Mobile and upwards
      return {
        copy: { y: undefined },
        image: { y: [ 10, -2 ] }
      };
    }

    return {
      copy: { y: undefined },
      image: { y: undefined }
    };
  }, [ width ]);

  return (
    <StyledIntervalFilms
      id="interval-films-section"
      className="interval-films-section "
    >
      <Panel
        className="content-container"
        gradient="royalBlueNavy"
      >
        <FullWidthColumnWrapper className="interval-films-section-wrapper">
          <div className="interval-films-section-content">
            <Parallax
              className="copy"
              y={parallaxXY.copy.y}
            >
              <h2>
                <span className="company">
                  Interval Films
                </span>

                <span className="off-screen">
                  {" - "}
                </span>

                <span className="blurb">
                  Best-in-class software platform for camera feeds
                </span>
              </h2>

              <ul>
                <li>
                  Designed and built in 10 days
                </li>

                <li>
                  Users onboarded within 4 weeks
                </li>

                <li>
                  Highly cost-effective
                </li>

                <li>
                  Daily collaboration with client
                </li>
              </ul>

              <Link
                type="styled"
                color="white"
                href="/projects/interval-films/"
              >
                View Project
              </Link>
            </Parallax>

            <Parallax
              className="image"
              y={parallaxXY.image.y}
            >
              <div className="image-wrapper">
                <div className="image-content">
                  <img
                    className="full"
                    src={IntervalFilmsAppImg}
                    alt="Interval Films time-lapse app on an iPhone"
                  />

                  <div className="impersonator">
                    <div className="hr" />
                  </div>

                  <div className="shadow" />
                </div>
              </div>
            </Parallax>
          </div>
        </FullWidthColumnWrapper>
      </Panel>
    </StyledIntervalFilms>
  );
};

const StyledIntervalFilms = styled.div`
  ${({ theme }) => css`
    overflow: hidden;

    .interval-films-section-wrapper {
      .interval-films-section-content {
        display: flex;
        flex-direction: column-reverse;
        transition: all 0.2s ease-in-out;
        position: relative;

        @media (min-width: 768px) {
          flex-direction: row-reverse;
          justify-content: flex-end;
          align-items: center;
        }

        @media (min-width: 1024px) {
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
        }

        @media (min-width: 1024px) {
          padding-top: 12%;
          padding-bottom: 12%;
        }

        @media (min-width: 1280px) {
          padding-top: 14%;
          padding-bottom: 14%;
        }

        @media (min-width: 1380px) {
          padding-top: 14%;
          padding-bottom: 14%;
        }

        @media (min-width: 1440px) {
          padding-top: 15%;
          padding-bottom: 15%;
        }

        .copy {
          z-index: 2;
          padding-top: 4.2rem;

          @media (min-width: 768px) {
            padding-top: 0;
            width: auto;
            max-width: 48.2rem;
          }

          @media (min-width: 1024px) {
            width: 48%;
            max-width: none;
          }

          @media (min-width: 1280px) {
            width: 52%;
          }

          @media (min-width: 1380px) {
            width: auto;
          }

          h2,
          ul {
            color: ${theme.color.white};
          }

          h2 {
            max-width: 64rem;

            span {
              display: block;

              &.company {
                text-transform: uppercase;
                font-size: ${theme.font.size.body_2};
                font-weight: ${theme.font.weight.bold};
                line-height: ${theme.font.lineHeight.normalized};
                letter-spacing: ${theme.font.spacing.medium};
              }

              &.blurb {
                padding-top: 3.2rem;
                font-size: ${theme.font.size.display_1};
                font-weight: ${theme.font.weight.heavy};

                @media (min-width: 480px) {
                  font-size: ${theme.font.size.display_3};
                }

                @media (min-width: 768px) {
                  font-size: ${theme.font.size.display_1};
                }

                @media (min-width: 1024px) {
                  width: 47.2rem;
                }

                @media (min-width: 1280px) {
                  width: 64rem;
                  font-size: ${theme.font.size.display_3};
                }
              }
            }
          }

          ul {
            padding-top: 4.4rem;
            font-size: ${theme.font.size.body_2};
          }

          a {
            padding-top: 6.2rem;
            font-size: ${theme.font.size.body_2};

            @media (min-width: 768px) {
              padding-top: 7.8rem;
            }

            @media (min-width: 1380px) {
              padding-top: 9.6rem;
            }
          }
        }

        .image {
          transition: all 0.2s ease-in-out;
          max-width: 57rem;

          @media (min-width: 768px) {
            margin-right: 6%;
          }

          @media (min-width: 1024px) {
            position: absolute;
            bottom: -20.6rem;
            left: 0;
            width: 41.4rem;
            margin: 0;
          }

          @media (min-width: 1280px) {
            bottom: -24rem;
            width: 49.6rem;
          }

          @media (min-width: 1380px) {
            bottom: -25rem;
            width: 52.8rem
          }

          @media (min-width: 1440px) {
            bottom: -28.8rem;
            width: 57rem;
          }

          .image-wrapper {
            margin: 0 auto;
            max-width: 33.5rem;

            @media (min-width: 768px) {
              max-width: 57rem;
            }

            @media (min-width: 1024px) {
              max-width: none;
            }

            .image-content {
              position: relative;
              padding-top: 150%;

              @media (min-width: 768px) {
                padding-top: 0;
              }

              img {
                display: none;
                position: relative;
                z-index: 2;

                @media (min-width: 768px) {
                  display: block;
                }
              }

              .impersonator {
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: url(${IntervalFilmsAppImg});
                background-repeat: no-repeat;
                background-position: top;
                background-size: 100% auto;

                @media (min-width: 768px) {
                  display: none;
                }

                .hr {
                  transition: all 0.2s ease-in-out;
                  position: absolute;
                  bottom: 0;
                  left: 50%;
                  transform: translateX(-50%);
                  width: 104%;
                  border-bottom: 0.1rem solid #667488;

                  @media (min-width: 768px) {
                    width: 110%;
                  }
                }
              }

              .shadow {
                position: absolute;
                bottom: 0;
                left: 14%;
                width: 72%;
                height: 90%;
                box-shadow: 0 0 4rem 0.5rem rgba(0,0,0,0.8);

                @media (min-width: 768px) {
                  top: 5%;
                  bottom: auto;
                  left: 5%;
                  width: 90%;
                  height: 90%;
                  box-shadow: 0 -0.5rem 6rem 2rem rgba(0,0,0,0.85);
                }

                @media (min-width: 1280px) {
                  box-shadow: 0 -1.8rem 10rem 3.2rem rgba(0,0,0,0.75);
                }
              }
            }
          }
        }
      }
    }
  `};
`;