import * as React from "react";
import { Parallax } from "react-scroll-parallax";
import { useWindowDimensions } from "@gravitywelluk/react-hooks";

import styled, { css } from "src/theme/styled";
import FullWidthColumnWrapper from "src/components/confines-wrappers/full-width-column-wrapper";
import WhyGravitywellImg from "src/assets/images/mvp/why-gravitywell-meeting.jpg";

/**
 * "Why Gravitywell" section
 */
export const WhyGravitywellSection: React.FC = () => {
  const { width } = useWindowDimensions();

  /**
   * Sets the x, y parallax start and end percentages according to the devices
   * dimensions.
   */
  const parallaxXY = React.useMemo(() => {
    if (width) {
      // Desktop and upwards
      if (width >= 1280) {
        return {
          copy: {
            x: [ 25, -4 ],
            y: undefined
          },
          image: { y: undefined }
        };
      }

      // Tablet (large) and upwards
      if (width >= 768) {
        return {
          copy: {
            x: [ 4, -2 ],
            y: undefined
          },
          image: { y: undefined }
        };
      }

      // Tablet (small) and upwards
      if (width >= 600) {
        return {
          copy: {
            x: undefined,
            y: undefined
          },
          image: { y: [ 8, -8 ] }
        };
      }

      // Mobile and upwards
      return {
        copy: {
          x: undefined,
          y: undefined
        },
        image: { y: [ 5, -5 ] }
      };
    }

    return {
      copy: {
        x: undefined,
        y: undefined
      },
      image: { y: undefined }
    };
  }, [ width ]);

  return (
    <StyledWhyGravitywellSection
      id="why-gravitywell-section"
      className="why-gravitywell-section content-container"
    >
      <FullWidthColumnWrapper className="why-gravitywell-section-wrapper">
        <Parallax
          className="copy"
          x={parallaxXY.copy.x}
          y={parallaxXY.copy.y}
        >
          <h2>
            Why Gravitywell?
          </h2>

          <p>
            Our award-winning team of developers and designers have a wealth of experience building market-ready prototypes and MVPs for startups and established businesses. Mobile and web apps, customer portals, bespoke software, and more.
          </p>
        </Parallax>

        <Parallax
          className="image"
          y={parallaxXY.image.y}
        >
          <img
            className="full"
            src={WhyGravitywellImg}
            alt="Gravitywell having a internal meeting about an upcoming project"
          />
        </Parallax>
      </FullWidthColumnWrapper>
    </StyledWhyGravitywellSection>
  );
};

const StyledWhyGravitywellSection = styled.div`
  ${({ theme }) => css`
    background: ${theme.color.altLightGrey};

    .why-gravitywell-section-wrapper {
      display: flex;
      flex-direction: column-reverse;

      @media (min-width: 768px) {
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
      }

      .copy {
        z-index: 2;
        padding-top: 4.2rem;

        @media (min-width: 768px) {
          padding-top: 0;
          width: 100%;
          max-width: 45.2rem;
        }

        h2 {
          font-size: ${theme.font.size.subhead_3};

          @media (min-width: 960px) {
            font-size: 3rem;
          }
        }

        p {
          font-size: ${theme.font.size.body_2};
        }
      }

      .image {
        max-width: 60rem;
        transition: all 0.2s ease-in-out;

        @media (min-width: 768px) {
          margin-right: 5%;
        }

        @media (min-width: 1024px) {
          margin-right: 6%;
        }

        @media (min-width: 1280px) {
          margin-right: 8%;
        }

        @media (min-width: 1380px) {
          margin-right: 10%;
        }
      }
    }
  `};
`;