import * as React from "react";

import styled, { css } from "src/theme/styled";
import FullWidthColumnWrapper from "src/components/confines-wrappers/full-width-column-wrapper";
import Panel from "src/components/panel-wrapper";
import Link from "src/components/link";
import GreenMotionParallaxApp from "src/components/green-motion-parallax-app";

/**
 * "Green Motion" section
 */
export const GreenMotionSection: React.FC = () => (
  <StyledGreenMotion
    id="interval-films-section"
    className="interval-films-section "
  >
    <Panel
      className="content-container"
      gradient="blueGreen"
    >
      <FullWidthColumnWrapper className="interval-films-section-wrapper">
        <div className="interval-films-section-content">
          <div className="copy">
            <h2>
              <span className="company">
                Green Motion
              </span>

              <span className="off-screen">
                {" - "}
              </span>

              <span className="blurb">
                Shortest distance from A to Beta
              </span>
            </h2>

            <ul>
              <li>
                Trial users instantly onboarded
              </li>

              <li>
                Smart integration of existing technology
              </li>

              <li>
                Focused set of mobile app features
              </li>

              <li>
                Efficient user feedback
              </li>
            </ul>

            <Link
              type="styled"
              color="white"
              href="/projects/green-motion/"
            >
              View Project
            </Link>
          </div>

          <GreenMotionParallaxApp
            className="image"
            parallax
          />
        </div>
      </FullWidthColumnWrapper>
    </Panel>
  </StyledGreenMotion>
);

const StyledGreenMotion = styled.div`
  ${({ theme }) => css`
    overflow: hidden;

    .interval-films-section-wrapper {
      .interval-films-section-content {
        display: flex;
        flex-direction: column-reverse;
        position: relative;
        transition: all 0.2s ease-in-out;

        @media (min-width: 768px) {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }

        @media (min-width: 1280px) {
          padding-top: 4%;
          padding-bottom: 4%;
        }

        .copy {
          transition: all 0.2s ease-in-out;
          z-index: 2;
          padding-top: 8.6rem;

          @media (min-width: 600px) {
            padding-top: 12.4rem;
          }

          @media (min-width: 768px) {
            margin-right: 6%;
            padding-top: 0;
            width: auto;
            max-width: 31.6rem;
          }

          @media (min-width: 960px) {
            margin-right: 0;
            max-width: 41.4rem;
          }

          @media (min-width: 1280px) {
            max-width: 54.5rem;
          }

          @media (min-width: 1380px) {
            width: auto;
          }

          h2,
          ul {
            color: ${theme.color.white};
          }

          h2 {
            span {
              display: block;

              &.company {
                text-transform: uppercase;
                font-size: ${theme.font.size.body_2};
                font-weight: ${theme.font.weight.bold};
                line-height: ${theme.font.lineHeight.normalized};
                letter-spacing: ${theme.font.spacing.medium};
              }

              &.blurb {
                padding-top: 3.2rem;
                font-size: ${theme.font.size.display_1};
                font-weight: ${theme.font.weight.heavy};

                @media (min-width: 480px) {
                  font-size: ${theme.font.size.display_3};
                }

                @media (min-width: 768px) {
                  font-size: ${theme.font.size.display_1};
                }

                @media (min-width: 1280px) {
                  font-size: ${theme.font.size.display_3};
                }
              }
            }
          }

          ul {
            padding-top: 4.4rem;
            font-size: ${theme.font.size.body_2};
          }

          a {
            padding-top: 6.2rem;
            font-size: ${theme.font.size.body_2};

            @media (min-width: 768px) {
              padding-top: 7.8rem;
            }

            @media (min-width: 1380px) {
              padding-top: 9.6rem;
            }
          }
        }

        .image {
          transition: all 0.2s ease-in-out;

          @media (min-width: 960px) {
            width: 48%;
          }

          @media (min-width: 1380px) {
            width: 50%;
          }

          @media (min-width: 1440px) {
            width: 54%;
          }
        }
      }
    }
  `};
`;