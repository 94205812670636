import * as React from "react";
import { useWindowDimensions } from "@gravitywelluk/react-hooks";

import styled, { css } from "src/theme/styled";
import { FullWidthColumnWrapper } from "src/components/confines-wrappers";
import { CtaButton } from "src/components/cta";
import PageTop from "src/components/page-top";
import VideoFallbackImgDesktop from "src/assets/images/mvp/mvp-hero-fallback.jpg";
import VideoFallbackImgMobile from "src/assets/images/mvp/mvp-hero-fallback-mobile.jpg";
import HeroVideo from "src/components/hero-video";

/**
 * MVP hero
 */
export const MvpHero: React.FC = () => {
  const { width } = useWindowDimensions();

  /**
   * Dynamic video fallback image (device width dependant)
   */
  const VideoFallbackImg = React.useMemo(() => {
    if (width && width < 768) {
      return VideoFallbackImgMobile;
    }

    return VideoFallbackImgDesktop;
  }, [ width ]);

  return (
    <>
      <PageTopCustom id="hero">
        <PageTop
          style={{
            paddingTop: 0,
            paddingBottom: 0
          }}
          height="large"
        >
          <HeroVideo
            src="https://cdn.gravitywell.co.uk/videos/mvp/Landing-Page-Hero_02.m3u8"
            fallbackImage={VideoFallbackImg}
          />

          <FullWidthColumnWrapper className="top-content">
            <p className="heading">
              Rapid production of your MVP or Prototype built on the latest technology.
            </p>
          </FullWidthColumnWrapper>
        </PageTop>
      </PageTopCustom>

      <CtaWrapper>
        <CtaButton
          type="primary"
          href="#get-in-touch"
          text="Work with us"
        />
      </CtaWrapper>
    </>
  );
};

const PageTopCustom = styled.div`
  ${({ theme }) => css`
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          padding-top: 10%;
          padding-bottom: 10%;
          z-index: 3;
          text-align: center;

          @media (min-width: 480px) {
            padding-top: 0;
            padding-bottom: 0;
          }

          .heading {
            display: block;
            transition: 0.2s ease-in-out;
            margin: 0 auto;
            padding-bottom: 12%;
            color: ${theme.color.white};
            font-size: ${theme.font.size.subhead_3};
            font-weight: ${theme.font.weight.heavy};
            line-height: ${theme.font.lineHeight.small};

            @media (min-width: 375px) {
              font-size: ${theme.font.size.display_1};
            }

            @media (min-width: 600px) {
              width: 56rem;
              font-size: ${theme.font.size.display_1};
            }

            @media (min-width: 768px) {
              width: 64.4rem;
              font-size: ${theme.font.size.display_2};
            }

            @media (min-width: 960px) {
              width: 82.8rem;
              font-size: ${theme.font.size.display_4};
            }

            @media (min-width: 1280px) {
              width: 94rem;
              font-size: ${theme.font.size.display_5};
            }

            @media (min-width: 1440px) {
              width: 106rem;
              font-size: ${theme.font.size.display_6};
            }
          }

          .cta-button {
            padding-top: 3.2rem;

            @media (min-width: 600px) {
              padding-top: 5.4rem;
            }

            @media (min-width: 768px) {
              padding-top: 6.2rem;
            }

            @media (min-width: 1280px) {
              padding-top: 7.8rem;
            }
          }
        }

      }
    }
  `};
`;

const CtaWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    padding-top: 3%;
    padding-bottom: 3%;
    background-color: ${theme.color.black};

    @media (min-width: 768px) {
      padding-top: 2.8rem;
      padding-bottom: 2.8rem;
    }
  `};
`;