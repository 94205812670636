import * as React from "react";
import { Parallax } from "react-scroll-parallax";
import { useWindowDimensions } from "@gravitywelluk/react-hooks";

import styled, { css } from "src/theme/styled";
import FullWidthColumnWrapper from "src/components/confines-wrappers/full-width-column-wrapper";
import WhyMvpWireframesImg from "src/assets/images/mvp/why-mvp-wireframes.jpg";

/**
 * "Why Minimum Viable Product(MVP)?" section
 */
export const WhyMvpSection: React.FC = () => {
  const { width } = useWindowDimensions();

  /**
   * Sets the x, y parallax start and end percentages according to the devices
   * dimensions.
   */
  const parallaxXY = React.useMemo(() => {
    if (width) {
      // Desktop and upwards
      if (width >= 1280) {
        return { y: [ -20, 20 ] };
      }

      // Tablet (large) and upwards
      if (width >= 768) {
        return { y: [ -15, 15 ] };
      }

      // Tablet (small) and upwards
      if (width >= 600) {
        return { y: [ -10, 10 ] };
      }

      // Mobile and upwards
      return { y: [ -4, 6 ] };
    }

    return { y: undefined };
  }, [ width ]);

  return (
    <StyledWhyMvpSection
      id="why-mvp-section"
      className="why-mvp-section content-container"
    >
      <FullWidthColumnWrapper className="why-mvp-section-wrapper">
        <Parallax
          className="copy"
          y={parallaxXY.y}
        >
          <h2>
            Why Minimum Viable Product
            <span className="break">
              (MVP)?
            </span>
          </h2>

          <ul>
            <li>
              Value for money
            </li>

            <li>
              Fast to market
            </li>

            <li>
              Build relationships
            </li>

            <li>
              Manage expectations
            </li>
          </ul>

          <p>
            Launch your startup app sooner and without unnecessary or unproven functionality. A Minimum Viable Product dictates a pragmatic approach to both development scope and your target users, for the first release of a new software product.
          </p>
        </Parallax>

        <div className="image">
          <img
            className="full"
            src={WhyMvpWireframesImg}
            alt="Wireframes of a mobile application"
          />
        </div>
      </FullWidthColumnWrapper>
    </StyledWhyMvpSection>
  );
};

const StyledWhyMvpSection = styled.div`
  ${({ theme }) => css`
    .why-mvp-section-wrapper {
      display: flex;
      flex-direction: column;

      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }

      .copy {
        z-index: 2;

        @media (min-width: 768px) {
          margin-right: 2%;
          width: 100%;
          max-width: 47rem;
        }

        @media (min-width: 1024px) {
          margin-right: 4%;
        }

        h2 {
          font-size: ${theme.font.size.subhead_3};

          @media (min-width: 960px) {
            font-size: 3rem;
          }

          .break {
            @media (min-width: 960px) {
              display: block;
            }
          }
        }

        ul,
        p {
          font-size: ${theme.font.size.body_2};

          @media (min-width: 768px) {
            width: 100%;
            max-width: 47rem;
          }

          @media (min-width: 1024px) {
            padding-top: 2.4em;
          }
        }
      }

      .image {
        padding-top: 4.2rem;

        @media (min-width: 768px) {
          padding-top: 0;
        }
      }
    }
  `};
`;