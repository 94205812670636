import * as React from "react";
import { Parallax } from "react-scroll-parallax";
import { useWindowDimensions } from "@gravitywelluk/react-hooks";
import styled, { css } from "styled-components";

import Icon from "src/components/icon";
import GreenMotionAppImg from "src/assets/images/mvp/green-motion-phone.png";

interface GreenMotionParallaxAppProps extends React.HTMLAttributes<HTMLDivElement> {
  parallax?: boolean;
}

/**
 * Green Motion parallax app
 */
const GreenMotionParallaxApp: React.FC<GreenMotionParallaxAppProps> = ({ parallax, ...rest }) => {
  const { width } = useWindowDimensions();

  /**
   * Sets the x, y parallax start and end percentages according to the devices
   * dimensions.
   */
  const parallaxXY = React.useMemo(() => {
    if (parallax && width) {
      // Desktop and upwards
      if (width >= 1280) {
        return {
          phone: { y: [ -10, 10 ] },
          icons: { y: [ -80, 80 ] }
        };
      }

      // Tablet (large) and upwards
      if (width >= 768) {
        return {
          phone: { y: [ -20, 20 ] },
          icons: { y: [ -25, 25 ] }
        };
      }

      // Mobile and upwards
      return {
        phone: { y: [ -15, 15 ] },
        icons: { y: [ -20, 20 ] }
      };
    }

    return {
      phone: { y: undefined },
      icons: { y: undefined }
    };
  }, [ parallax, width ]);

  return (
    <StyledGreenMotionParallaxApp {...rest}>
      <Parallax
        className="green-motion-phone"
        y={parallaxXY.phone.y}
      >
        <div className="green-motion-phone-wrapper">
          <img
            className="full"
            src={GreenMotionAppImg}
            alt="An iPhone with the unlock car screen on the Green Motion App"
          />

          <div className="shadow" />
        </div>
      </Parallax>

      <Parallax
        className="annotation annotation-left driving-license"
        y={parallaxXY.icons.y}
      >
        <span className="item">
          <span className="text">
            Scan your documents for verification
          </span>

          <span className="icon-wrapper">
            <Icon
              className="icon"
              icon="greenmotionDrivingLicense"
            />
          </span>
        </span>
      </Parallax>

      <Parallax
        className="annotation annotation-right queues"
        y={parallaxXY.icons.y}
      >
        <span className="item">
          <span className="text">
            No more queues, no more paperwork at the desk
          </span>

          <span className="icon-wrapper">
            <Icon
              className="icon"
              icon="greenmotionDocument"
            />
          </span>
        </span>
      </Parallax>

      <Parallax
        className="annotation annotation-left unlock"
        y={parallaxXY.icons.y}
      >
        <span className="item">
          <span className="text">
            Go straight to your car and unlock with the app
          </span>

          <span className="icon-wrapper">
            <Icon
              className="icon"
              icon="greenmotionPadlock"
            />
          </span>
        </span>
      </Parallax>
    </StyledGreenMotionParallaxApp>
  );
};

export default GreenMotionParallaxApp;

const StyledGreenMotionParallaxApp = styled.div`
  ${({ theme }) => css`
    position: relative;

    .green-motion-phone {
      .green-motion-phone-wrapper {
        transition: all 0.2s ease-in-out;
        position: relative;
        margin: 0 auto;
        width: 30%;

        @media (min-width: 768px) {
          width: 36%;
        }

        @media (min-width: 1380px) {
          width: 38.6%
        }

        @media (min-width: 1440px) {
          width: 40.2%;
        }

        img {
          position: relative;
          z-index: 2;
        }

        .shadow {
          position: absolute;
          top: 5%;
          left: 5%;
          width: 90%;
          height: 90%;
          box-shadow: 0 0 4rem 0.5rem rgba(0,0,0,0.8);

          @media (min-width: 1280px) {
            box-shadow: 0 1rem 5rem 1rem rgba(0,0,0,0.75);
          }

          @media (min-width: 1440px) {
            box-shadow: 0 1rem 6rem 1.5rem rgba(0,0,0,0.75);
          }
        }
      }
    }

    .annotation {
      position: absolute;
      z-index: 2;
      width: 40%;

      @media (min-width: 480px) {
        width: 38%;
      }

      @media (min-width: 600px) {
        width: 38.5%;
      }

      @media (min-width: 768px) {
        width: 36%;
      }

      @media (min-width: 1380px) {
        width: 35%;
      }

      @media (min-width: 1440px) {
        width: 34%;
      }

      .item {
        display: flex;
        justify-content: center;
        align-items: center;

        .text {
          color: ${theme.color.white};
          font-size: ${theme.font.size.small_1};
          line-height: ${theme.font.lineHeight.default};

          @media (min-width: 480px) {
            font-size: ${theme.font.size.small_2};
          }

          @media (min-width: 600px) {
            font-size: ${theme.font.size.small_3};
          }

          @media (min-width: 768px) {
            font-size: ${theme.font.size.small_2};
          }

          @media (min-width: 960px) {
            font-size: ${theme.font.size.body_1};
          }
        }

        .icon-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 2.5%;
          background-color: rgba(255, 255, 255, 0.9);
          border: 2px solid ${theme.color.white};
          border-radius: 1000rem;

          @media (min-width: 1280px) {
            padding: 3%;
          }

          .icon {
            width: 1.6rem !important;
            height: auto;
            fill: ${theme.color.brandThreeInverse};

            @media (min-width: 600px) {
              width: 2.2rem !important;
            }

            @media (min-width: 768px) {
              width: 1.8rem !important;
            }

            @media (min-width: 1280px) {
              width: 2.2rem !important;
            }

            @media (min-width: 1380px) {
              width: 3.2rem !important;
            }
          }
        }
      }

      &.annotation-left {
        .item {
          flex-direction: row;

          .text {
            padding-right: 6%;
            text-align: right;

            @media (min-width: 1380px) {
              padding-right: 8%;
            }
          }
        }
      }

      &.annotation-right {
        .item {
          flex-direction: row-reverse;

          .text {
            padding-left: 6%;

            @media (min-width: 1380px) {
              padding-left: 8%;
            }
          }
        }
      }

      &.driving-license,
      &.unlock {
        left: 0;
      }

      &.driving-license {
        top: 6%;

        @media (min-width: 1280px) {
          top: 14%;
        }
      }

      &.queues {
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }

      &.unlock {
        bottom: 6%;

        @media (min-width: 1280px) {
          bottom: 14%;
        }
      }
    }
  `};
`;