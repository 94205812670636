import * as React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import Layout from "src/components/layout";
import { breadCrumbSchema } from "src/components/html-schema-snippets";
import HelmetWrapper from "src/components/helmet-wrapper";
import Header from "src/components/header";
import {
  MvpHero,
  WhyMvpSection,
  WhyGravitywellSection,
  IntervalFilmsSection,
  GreenMotionSection
} from "src/components/mvp";
import Insights from "src/components/insights";
import getProfile from "src/templates/utils/getProfile";
import { IArticleData } from "src/components/articles-grid/articles-grid";
import Testimonials from "src/components/Testimonials";
import GetInTouch from "src/components/get-in-touch";
import Footer from "src/components/footer";
import { CtaButton } from "src/components/cta";

import { IInsightsArticleData } from "./insights";

interface MvpProps {
  data: LabsArticleGraphQLProps;
}

export interface LabsArticleGraphQLProps {
  allNodeLabsArticle: {
    edges: LabsArticleProps[];
  };
}

interface LabsArticleProps {
  node: IInsightsArticleData;
}

/**
 * MVP landing page
 */
const Mvp: React.FC<MvpProps> = ({ data }) => {
  const canonicalPath = "/mvp";

  /**
   * Bread crumbs schema data
   */
  const breadcrumbsSchemaData = React.useMemo(() => breadCrumbSchema([
    {
      name: "Rapid production of your MVP or Prototype",
      path: canonicalPath
    }
  ]), []);

  return (
    <Layout>
      <>
        <HelmetWrapper
          title="MVP / Prototype - Rapid production of your project"
          description="Rapid production of your MVP or Prototype built on the latest technology."
          canonicalPath={canonicalPath}
        >
          <script type="application/ld+json">
            {JSON.stringify([ breadcrumbsSchemaData ])}
          </script>
        </HelmetWrapper>

        <header role="banner">
          <Header
            initialLogo
            initialTheme="dark"
          />

          <MvpHero />
        </header>

        <main
          id="main-content"
          role="main"
        >
          <h1 className="off-screen">
            Rapid production of your MVP or Prototype
          </h1>

          <WhyMvpSection />

          <WhyGravitywellSection />

          <IntervalFilmsSection />

          <GreenMotionSection />

          <aside>
            <Testimonials />

            <h2 className="off-screen">
              Latest posts
            </h2>

            <Insights
              title="Insights"
              gradient="orangePink"
              articles={data.allNodeLabsArticle.edges.map(post => {
                const profile = getProfile(post.node.relationships.author);

                const articleObj: IArticleData = {
                  created: post.node.created,
                  path: post.node.path.alias,
                  title: post.node.title,
                  author: profile.field_full_name,
                  cover: post.node.relationships.field_cover_image
                };

                return articleObj;
              })}
            />
          </aside>
        </main>

        <Styled.FloatingCTA>
          <CtaButton
            type="primary"
            href="#get-in-touch"
            text="Work with us"
            className="floating-cta"
          />
        </Styled.FloatingCTA>

        <footer
          role="contentinfo"
          style={{
            position: "relative",
            zIndex: 3
          }}
        >
          <GetInTouch
            title="Make it viable"
            slogan="Have you got a great MVP idea?"
            links={[
              {
                text: "More of our work",
                href: "/projects/"
              },
              {
                text: "Our process",
                href: "/about-us/"
              },
              {
                text: "Meet the team",
                href: "/about-us/#meet-the-team"
              }
            ]}
          />

          <Footer />
        </footer>
      </>
    </Layout>
  );
};

const Styled = {
  FloatingCTA: styled.div`
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 3;
    margin: 2rem;
  `
};

export default Mvp;

export const query = graphql`
  query mvpPageQuery {
    allNodeLabsArticle(
      filter: { drupal_internal__nid: { in: [229, 231, 241] } }
    ) {
      edges {
        node {
          ...getLabsData
        }
      }
    }
  }
`;
